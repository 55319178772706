import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
	name: "dateTransform"
})
export class DateTransformPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) return value;

		const date = DateTime.fromISO(value);

		if (!date.isValid) return value;

		return date.toFormat("dd/MM/yyyy");
	}
}

<atlas-form [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			<atlas-col lg="12">
				<atlas-section
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.WICH_CERTIFICATE'
							| translate
					"
					hide-optional=""
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-toggle>
									<atlas-toggle-item
										[checked]="true"
										value="a1"
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.A1_CERTIFICATE'
												| translate
										"
										(atlas-toggle-item-change)="
											changeCertificateType($event)
										"
									></atlas-toggle-item>
									<atlas-toggle-item
										value="a2"
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.A3_CERTIFICATE'
												| translate
										"
									></atlas-toggle-item>
								</atlas-toggle>
							</atlas-col>
						</atlas-row>

						@if (digitalCertificateType() === "a1") {
							<atlas-row>
								<atlas-col lg="12">
									<atlas-text muted="">
										{{
											"NFE_WIZARD_CONFIGURATION.LABEL.A1_CERTIFICATE_DESCRIPTION"
												| translate
										}}</atlas-text
									>
								</atlas-col>
							</atlas-row>
							@if (!showValidatedCertificateOnList()) {
								<atlas-row>
									<atlas-col lg="12">
										<atlas-uploader
											#atlasUploader
											[fileList]="true"
											(atlas-uploader-success)="
												uploadSuccess()
											"
											accepted-files=".pfx"
											[required]="true"
										></atlas-uploader>
									</atlas-col>
								</atlas-row>
							}
							@if (showPasswordInput()) {
								<atlas-row>
									<atlas-col lg="7">
										<atlas-input
											appAtlasFormElement
											formControlName="digitalCertificateFileName"
											[label]="
												'NFE_WIZARD_CONFIGURATION.LABEL.FILE_NAME'
													| translate
											"
											[disabled]="true"
											[required]="true"
										></atlas-input>
									</atlas-col>
									<atlas-col lg="3">
										<atlas-password-input
											appAtlasFormElement
											formControlName="digitalCertificatePassword"
											[label]="
												'NFE_WIZARD_CONFIGURATION.LABEL.CERTIFICATE_PASSWORD'
													| translate
											"
											[required]="true"
										></atlas-password-input>
									</atlas-col>
								</atlas-row>
							}

							@if (showValidatedCertificateOnList()) {
								<atlas-table>
									<atlas-table-header slot="header">
										<atlas-table-col
											name="digitalCertificateFileName"
											size="lg"
											>{{
												"NFE_WIZARD_CONFIGURATION.LABEL.FILE_NAME"
													| translate
											}}</atlas-table-col
										>
										<atlas-table-col
											name="digitalCertificateExpirationDate"
											size="sm"
											>{{
												"NFE_WIZARD_CONFIGURATION.LABEL.VALID_UNTIL"
													| translate
											}}</atlas-table-col
										>
									</atlas-table-header>

									<atlas-table-body slot="body">
										<atlas-table-row>
											<atlas-table-col>{{
												form.controls
													.digitalCertificateFileName
													.value
											}}</atlas-table-col>
											<atlas-table-col>{{
												form.controls
													.digitalCertificateExpirationDate
													.value! | dateTransform
											}}</atlas-table-col>
											<atlas-table-col>
												<atlas-button
													type="ghost"
													size="sm"
													icon="trash"
													theme="secondary"
													(atlas-button-click)="
														removeDigitalCertificate()
													"
												></atlas-button>
											</atlas-table-col>
										</atlas-table-row>
									</atlas-table-body>
								</atlas-table>
							}
						} @else {
							@if (webPkiNotInstalled()) {
								<atlas-row>
									<atlas-col lg="12">
										<atlas-text
											muted=""
											[innerHTML]="
												'NFE_WIZARD_CONFIGURATION.LABEL.DIGITAL_CERTIFICATE_A3_GUIDE'
													| translate
											"
										></atlas-text>
									</atlas-col>
								</atlas-row>
								<atlas-row>
									<atlas-col lg="12">
										<atlas-button
											(click)="installWebPki()"
											[description]="
												'NFE_WIZARD_CONFIGURATION.LABEL.PLUGIN_INSTALL'
													| translate
											"
											icon="upload"
										></atlas-button>
									</atlas-col>
								</atlas-row>
							} @else {
								<atlas-row>
									<atlas-col lg="12">
										<atlas-text muted="">
											{{
												"NFE_WIZARD_CONFIGURATION.LABEL.DIGITAL_CERTIFICATE_A3_DESCRIPTION"
													| translate
											}}</atlas-text
										>
									</atlas-col>
								</atlas-row>
								<atlas-row>
									<atlas-col lg="6">
										<atlas-select
											[label]="
												'NFE_WIZARD_CONFIGURATION.LABEL.A3_SELECT_LABEL'
													| translate
											"
											[placeholder]="
												'NFE_WIZARD_CONFIGURATION.LABEL.A3_SELECT_DESCRIPTION'
													| translate
											"
											appAtlasFormElement
											formControlName="digitalCertificateA3"
											[value]="
												form.controls
													.digitalCertificateA3.value
											"
											[required]="true"
											[enableSearch]="true"
											(atlas-select-change)="
												selectedCertificateA3($event)
											"
										>
											@for (
												certificate of digitalCertificatesA3();
												track certificate.thumbprint
											) {
												<atlas-option
													[value]="certificate"
													[label]="
														certificate?.selectFormatted
													"
													[disabled]="
														!certificate.valid
													"
												></atlas-option>
											}
										</atlas-select>
									</atlas-col>
								</atlas-row>
							}
						}
					</atlas-grid>
				</atlas-section>
			</atlas-col>
		</atlas-row>
	</atlas-grid>
</atlas-form>

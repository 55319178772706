import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { environment } from "@env";

@Component({
	selector: "app-taxes-data-step",
	templateUrl: "./taxes-data-step.component.html",
	styleUrls: ["./taxes-data-step.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxesDataStepComponent implements OnInit {
	protected isDevEnv = false;

	constructor() {}

	ngOnInit() {
		this.isDevEnv = !environment.production;
	}
}

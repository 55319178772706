import { FormControl } from "@angular/forms";
import { BaseStepController } from "@asaasdev/atlas/dist/components/wizard/atlas-wizard/types";

export class DigitalCertificateStepController implements BaseStepController {
	constructor(private digitalCertificateValidated: FormControl) {}

	init(): () => void {
		return () => {};
	}

	onShowStep(): () => void {
		return () => {};
	}

	onSubmitStep(
		resolve: (value: boolean | PromiseLike<boolean>) => void
	): void {
		this.validate((isValid) => {
			if (isValid) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	}

	validate(resolve: (value: boolean | PromiseLike<boolean>) => void): void {
		resolve(this.digitalCertificateValidated.value as boolean);
	}
}

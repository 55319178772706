<atlas-grid>
	<atlas-row>
		<atlas-col lg="12">
			@if (!isDevEnv) {
				<atlas-section>
					<atlas-empty-state
						illustration="files-notification-notebook"
						[header]="
							'NFE_WIZARD_CONFIGURATION.HEADER.TAXES_EMPTY_STATE'
								| translate
						"
						[description]="
							'NFE_WIZARD_CONFIGURATION.LABEL.TAXES_EMPTY_STATE_DESCRIPTION'
								| translate
						"
					></atlas-empty-state>
				</atlas-section>
			} @else {
				<atlas-section
					hide-optional=""
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.TAXATION_SUGGESTIONS'
							| translate
					"
				>
					<atlas-text muted="">
						{{
							"NFE_WIZARD_CONFIGURATION.LABEL.TAXATION_SUGGESTIONS_DESCRIPTION"
								| translate
						}}</atlas-text
					></atlas-section
				>

				<atlas-section
					hide-optional=""
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.SAME_STATE_SALE'
							| translate
					"
					[description]="
						'NFE_WIZARD_CONFIGURATION.LABEL.SAME_STATE_SALE_DESCRIPTION'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="6">
								<atlas-table>
									<atlas-table-header slot="header">
										<atlas-table-col
											name="taxType"
										></atlas-table-col>
										<atlas-table-col
											name="taxName"
											size="lg"
											ellipsis
										></atlas-table-col>
									</atlas-table-header>

									<atlas-table-body slot="body">
										<atlas-table-row>
											<atlas-table-col
												>CFOP</atlas-table-col
											>
											<atlas-table-col></atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>ICMS</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select> </atlas-select>
											</atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>PIS</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select></atlas-select>
											</atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>CONFINS</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select></atlas-select>
											</atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>IPI</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select></atlas-select>
											</atlas-table-col>
										</atlas-table-row>
									</atlas-table-body>
								</atlas-table>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>

				<atlas-section
					hide-optional=""
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.OTHER_STATES_SALE'
							| translate
					"
					[description]="
						'NFE_WIZARD_CONFIGURATION.LABEL.OTHER_STATES_SALE_DESCRIPTION'
							| translate
					"
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="6">
								<atlas-table>
									<atlas-table-header slot="header">
										<atlas-table-col
											name="taxType"
										></atlas-table-col>
										<atlas-table-col
											name="taxName"
											size="lg"
											ellipsis
										></atlas-table-col>
									</atlas-table-header>

									<atlas-table-body slot="body">
										<atlas-table-row>
											<atlas-table-col
												>CFOP</atlas-table-col
											>
											<atlas-table-col></atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>ICMS</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select> </atlas-select>
											</atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>PIS</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select></atlas-select>
											</atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>CONFINS</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select></atlas-select>
											</atlas-table-col>
										</atlas-table-row>

										<atlas-table-row>
											<atlas-table-col
												>IPI</atlas-table-col
											>
											<atlas-table-col>
												<atlas-select></atlas-select>
											</atlas-table-col>
										</atlas-table-row>
									</atlas-table-body>
								</atlas-table>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>
			}
		</atlas-col>
	</atlas-row>
</atlas-grid>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	DigitalCertificateValidationFile,
	DigitalCertificateValidationResponse
} from "@data/schemas/digital-certificate/digital-certificate-validation-file";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class DigitalCertificateService {
	readonly DIGITAL_CERTIFICATE_ENDPOINT = "digital-certificates";

	constructor(private http: HttpClient) {}

	validateFile(
		digitalCertificateValidationFile: DigitalCertificateValidationFile
	): Observable<DigitalCertificateValidationResponse> {
		const formData = new FormData();

		formData.append("file", digitalCertificateValidationFile.file);
		formData.append("password", digitalCertificateValidationFile.password);

		return this.http.post<DigitalCertificateValidationResponse>(
			`${this.DIGITAL_CERTIFICATE_ENDPOINT}/validation-file`,
			formData,
			{
				withCredentials: true
			}
		);
	}
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InvoiceConfiguration } from "@data/schemas/invoice-configuration/invoice-configuration";
import { Observable, Subject } from "rxjs";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { v4 as uuidv4 } from "uuid";

export type WizardTrackingEvent =
	| "click_configure"
	| "step_tax_data"
	| "emission_environment"
	| "step_certificate_digital"
	| "step_summary"
	| "summary_finish";

@Injectable({
	providedIn: "root"
})
export class WizardInvoiceConfigurationService {
	readonly INVOICE_CONFIGURATION_WIZARD_ENDPOINT =
		"wizard/invoice-configuration";

	private updateSubject = new Subject<void>();
	private interactionTrackingId: string = uuidv4();

	update$ = this.updateSubject.asObservable();

	emitUpdate(): void {
		this.updateSubject.next();
	}

	constructor(
		private http: HttpClient,
		private segment: CustomSegmentService
	) {}

	sendInvoiceConfiguration(
		invoiceConfiguration: InvoiceConfiguration
	): Observable<void> {
		return this.http.post<void>(
			`${this.INVOICE_CONFIGURATION_WIZARD_ENDPOINT}`,
			invoiceConfiguration,
			{
				withCredentials: true
			}
		);
	}

	public trackWizard(
		event: WizardTrackingEvent,
		properties?: { [k: string]: unknown }
	): void {
		this.interactionTrackingId = this.getInteractionTrackingId(properties);

		this.segment.track({
			table: "track_configuration_nfe",
			screen: "wizard",
			action: `base_erp_invoice_configuration_${event}`,
			properties: {
				...(properties || {}),
				interaction_id: this.interactionTrackingId
			}
		});
	}

	private getInteractionTrackingId(properties?: {
		[k: string]: unknown;
	}): string {
		return (properties?.interaction_id ||
			this.interactionTrackingId ||
			uuidv4()) as string;
	}
}

import { inject, Injectable, NgZone } from "@angular/core";
import { LacunaWebPKI, CertificateModel, ExceptionModel } from "web-pki";
import { environment } from "@env";
import { DatadogService } from "@core/services/datadog.service";
import { Subject } from "rxjs";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";

export interface BaseCertificateA3Model extends CertificateModel {
	valid?: boolean;
	selectFormatted?: string;
}

@Injectable({
	providedIn: "root"
})
export class WebPkiService {
	private ngZone: NgZone = inject(NgZone);
	private datadogService: DatadogService = inject(DatadogService);
	private automataTranslateService: AutomataTranslateService = inject(
		AutomataTranslateService
	);

	private pki: LacunaWebPKI;
	private webPkiLicense: string = environment.WEB_PKI_LICENSE ?? "";

	private certificates = new Subject<BaseCertificateA3Model[] | null>();
	private selectedCertificate = new Subject<BaseCertificateA3Model | null>();
	private webPkiNotInstalled = new Subject<boolean | null>();
	private webPkiFail = new Subject<ExceptionModel | null>();
	private webPkiIsReady = new Subject<boolean | null>();

	public certificates$ = this.certificates.asObservable();
	public selectedCertificate$ = this.selectedCertificate.asObservable();
	public webPkiNotInstalled$ = this.webPkiNotInstalled.asObservable();
	public webPkiFail$ = this.webPkiFail.asObservable();
	public webPkiIsReady$ = this.webPkiIsReady.asObservable();

	constructor() {
		this.pki = new LacunaWebPKI(this.webPkiLicense);
	}

	public init() {
		void this.pki.init({
			ngZone: this.ngZone as object,
			ready: this.onWebPkiReady,
			notInstalled: this.onWebPkiNotInstalled,
			defaultFail: void this.onWebPkiFail,
			brand: "baseerp"
		});
	}

	public selectCertificate = (certificate: BaseCertificateA3Model) => {
		this.selectedCertificate.next(certificate);
	};

	public redirectToInstallPage = () => {
		this.pki.redirectToInstallPage();
	};

	public selectOptionFormatter(cert: BaseCertificateA3Model) {
		const validUntil = this.automataTranslateService.getTranslationSync(
			"NFE_WIZARD_CONFIGURATION.LABEL.VALID_UNTIL"
		);
		const validityDate = new Date(cert.validityEnd).toLocaleDateString();
		return `${cert.subjectName} ( ${validUntil} ${validityDate} )`;
	}

	private onWebPkiReady = () => {
		this.pki.listCertificates().success((certs) => {
			(certs as BaseCertificateA3Model[]).map((cert) => {
				cert["valid"] = this.isCertificateValidBasedOnDates(cert);
				cert["selectFormatted"] = this.selectOptionFormatter(cert);
				return cert;
			});

			this.webPkiIsReady.next(true);
			this.certificates.next(certs);
		});
	};

	private onWebPkiNotInstalled = (
		_: LacunaWebPKI.InstallationStates,
		__: string
	) => {
		this.webPkiNotInstalled.next(true);
	};

	private onWebPkiFail = (ex: ExceptionModel) => {
		this.webPkiFail.next(ex);
		this.datadogService.customLog(
			"error",
			`Web PKI error ${ex.code}: ${ex.message}`,
			ex as unknown as { [x: string]: unknown }
		);
	};

	private isCertificateValidBasedOnDates(
		certificate: BaseCertificateA3Model
	): boolean {
		const now = new Date();
		const validityStart = new Date(certificate.validityStart);
		const validityEnd = new Date(certificate.validityEnd);
		return validityStart <= now && now <= validityEnd;
	}
}

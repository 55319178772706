<atlas-form [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			<atlas-col lg="12">
				<atlas-section
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.WICH_TAX_DATA'
							| translate
					"
					hide-optional=""
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-input
									appAtlasFormElement
									formControlName="stateRegistration"
									[label]="
										'NFE_WIZARD_CONFIGURATION.LABEL.STATE_REGISTRATION_LABEL'
											| translate
									"
									[placeholder]="
										'NFE_WIZARD_CONFIGURATION.LABEL.STATE_REGISTRATION_PLACEHOLDER'
											| translate
									"
									[popoverTitle]="
										'NFE_WIZARD_CONFIGURATION.LABEL.STATE_REGISTRATION_POPOVER_TITLE'
											| translate
									"
									[popoverContent]="
										'NFE_WIZARD_CONFIGURATION.LABEL.STATE_REGISTRATION_POPOVER_CONTENT'
											| translate
									"
									[required]="true"
									(keypress)="allowOnlyNumbers($event)"
								></atlas-input>
							</atlas-col>
						</atlas-row>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-select
									appAtlasFormElement
									formControlName="taxRegime"
									[label]="
										'NFE_WIZARD_CONFIGURATION.LABEL.TAX_REGIME_LABEL'
											| translate
									"
									[placeholder]="
										'NFE_WIZARD_CONFIGURATION.LABEL.TAX_REGIME_PLACEHOLDER'
											| translate
									"
									[required]="true"
								>
									<atlas-option
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.SIMPLE_NATIONAL'
												| translate
										"
										value="nationalSimple"
									>
									</atlas-option>
									<atlas-option
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.SIMPLE_WITH_GROSS_INCOME'
												| translate
										"
										value="simpleWithGrossIncome"
									>
									</atlas-option>
									<atlas-option
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.NORMAL_REGIME'
												| translate
										"
										value="normalRegime"
									>
									</atlas-option>
									<atlas-option label="MEI" value="mei">
									</atlas-option>
								</atlas-select>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>

				<atlas-section
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.WICH_ENVIRONMENT'
							| translate
					"
					hide-optional=""
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-toggle
									required="true"
									default-value="approval"
								>
									<atlas-toggle-item
										[checked]="true"
										value="approval"
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.APPROVAL_TOGGLE'
												| translate
										"
										(atlas-toggle-item-change)="
											changeEnvironment($event)
										"
									></atlas-toggle-item>
									<atlas-toggle-item
										value="production"
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.PRODUCTION_TOGGLE'
												| translate
										"
									></atlas-toggle-item>
								</atlas-toggle>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>

				<atlas-section
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.ISSUE_NUMBERS'
							| translate
					"
					[description]="
						'NFE_WIZARD_CONFIGURATION.HEADER.ISSUE_NUMBERS_DESCRIPTION'
							| translate
					"
					hide-optional=""
				>
					<atlas-grid>
						<atlas-row>
							<atlas-col lg="4">
								<atlas-integer-input
									appAtlasFormElement
									formControlName="nfeNumber"
									min-value="1"
									[label]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_NUMBER_LABEL'
											| translate
									"
									[placeholder]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_NUMBER_PLACEHOLDER'
											| translate
									"
									[popoverContent]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_NUMBER_POPOVER'
											| translate
									"
									[required]="true"
								></atlas-integer-input>
							</atlas-col>
							<atlas-col lg="4">
								<atlas-integer-input
									appAtlasFormElement
									formControlName="nfeSeries"
									min-value="1"
									[minValueErrorMessage]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_SERIES_MIN_VALUE'
											| translate
									"
									max-value="999.999"
									[label]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_SERIES_LABEL'
											| translate
									"
									[placeholder]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_SERIES_PLACEHOLDER'
											| translate
									"
									[popoverContent]="
										'NFE_WIZARD_CONFIGURATION.LABEL.NFE_SERIES_POPOVER'
											| translate
									"
									[required]="true"
								></atlas-integer-input>
							</atlas-col>
						</atlas-row>
					</atlas-grid>
				</atlas-section>

				<atlas-section
					[header]="
						'NFE_WIZARD_CONFIGURATION.HEADER.INTERESTED_ACTORS'
							| translate
					"
					[description]="
						'NFE_WIZARD_CONFIGURATION.HEADER.INTERESTED_ACTORS_DESCRIPTION'
							| translate
					"
					hide-optional=""
				>
					<fieldset formGroupName="actorGroup">
						<atlas-grid>
							<atlas-row>
								<atlas-col lg="4">
									<atlas-input
										#newActorName
										appAtlasFormElement
										formControlName="newActorName"
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.ACCOUNTING_NAME_LABEL'
												| translate
										"
										[placeholder]="
											'NFE_WIZARD_CONFIGURATION.LABEL.ACCOUNTING_NAME_PLACEHOLDER'
												| translate
										"
										[disabled]="
											interestedActorsDisableControl
										"
									></atlas-input>
								</atlas-col>
								<atlas-col lg="4">
									<atlas-masked-input
										#newActorTaxpayerRegistration
										appAtlasFormElement
										formControlName="newActorTaxpayerRegistration"
										mask-alias="cpf-cnpj"
										[label]="
											'NFE_WIZARD_CONFIGURATION.LABEL.TAXPAYER_REGISTRATION_LABEL'
												| translate
										"
										[placeholder]="
											'NFE_WIZARD_CONFIGURATION.LABEL.TAXPAYER_REGISTRATION_PLACEHOLDER'
												| translate
										"
										[disabled]="
											interestedActorsDisableControl
										"
									></atlas-masked-input>
								</atlas-col>

								<atlas-col lg="2">
									<atlas-button
										class="button"
										[description]="
											'NFE_WIZARD_CONFIGURATION.LABEL.INCLUDE_NEW_ACTOR_BUTTON'
												| translate
										"
										type="outlined"
										(atlas-button-click)="includeNewActor()"
										[disabled]="
											interestedActorsDisableControl
										"
									></atlas-button>
								</atlas-col>
							</atlas-row>
						</atlas-grid>
						@if (interestedActors.length > 0) {
							<atlas-table>
								<atlas-table-header slot="header">
									<atlas-table-col
										name="actorName"
										size="lg"
										>{{
											"NFE_WIZARD_CONFIGURATION.LABEL.ACTOR_NAME_COLUMN"
												| translate
										}}</atlas-table-col
									>
									<atlas-table-col
										name="taxpayerRegistration"
										size="md"
										>{{
											"NFE_WIZARD_CONFIGURATION.LABEL.TAXPAYER_REGISTRATION_COLUMN"
												| translate
										}}</atlas-table-col
									>
								</atlas-table-header>

								<atlas-table-body slot="body">
									@for (
										actor of interestedActors.controls;
										track actor.value.id
									) {
										<atlas-table-row>
											<atlas-table-col>
												{{ actor.value.actorName }}
											</atlas-table-col>
											<atlas-table-col>
												{{
													actor.value
														.taxpayerRegistration
												}}
											</atlas-table-col>
											<atlas-table-col>
												<atlas-button
													type="ghost"
													size="sm"
													theme="danger"
													icon="trash"
													(atlas-button-click)="
														removeActor(
															actor.value.id
														)
													"
												></atlas-button>
											</atlas-table-col>
										</atlas-table-row>
									}
								</atlas-table-body>
							</atlas-table>
						}
					</fieldset>
				</atlas-section>
			</atlas-col>
		</atlas-row>
	</atlas-grid>
</atlas-form>

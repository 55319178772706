<atlas-section
	[header]="'NFE_WIZARD_CONFIGURATION.HEADER.SUMMARY_SECTION' | translate"
>
	<atlas-summary-container>
		<atlas-summary-card
			[header]="
				'NFE_WIZARD_CONFIGURATION.HEADER.COMPANY_DATA_CARD' | translate
			"
			[relatedStep]="steps.FISCAL_DATA"
			[expandedVertically]="false"
		>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.STATE_REGISTRATION_LABEL'
						| translate
				"
				[description]="
					getFormControlValueFiscalDataStep('stateRegistration')
				"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.TAX_REGIME_LABEL'
						| translate
				"
				[description]="taxRegimeLabel"
			></atlas-summary-item>
		</atlas-summary-card>

		<atlas-summary-card
			[header]="
				'NFE_WIZARD_CONFIGURATION.HEADER.INTERESTED_ACTORS_CARD'
					| translate
			"
			[relatedStep]="steps.FISCAL_DATA"
		>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.INTERESTED_ACTOR'
						| translate
				"
				[description]="getActorsInformation().firstActorName"
			></atlas-summary-item>
			<atlas-summary-item
				label="CNPJ/CPF"
				[description]="
					getActorsInformation().firstActorTaxpayerRegistration
				"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.OTHER_ACTORS' | translate
				"
				[description]="getActorsInformation().count"
			></atlas-summary-item>
		</atlas-summary-card>

		<atlas-summary-card
			[header]="
				'NFE_WIZARD_CONFIGURATION.HEADER.INVOICE_NUMBERING' | translate
			"
			[relatedStep]="steps.FISCAL_DATA"
		>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.ENVIRONMENT_TYPE'
						| translate
				"
				[description]="getEnvironmentConfigLabel()"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.NFE_NUMBER_LABEL'
						| translate
				"
				[description]="getFormControlValueFiscalDataStep('nfeNumber')"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.NFE_SERIES_LABEL'
						| translate
				"
				[description]="getFormControlValueFiscalDataStep('nfeSeries')"
			></atlas-summary-item>
		</atlas-summary-card>

		<atlas-summary-card
			[header]="
				'NFE_WIZARD_CONFIGURATION.HEADER.DIGITAL_CERTIFICATE'
					| translate
			"
			[relatedStep]="steps.DIGITAL_CERTIFICATE"
		>
			<atlas-summary-item
				[label]="'NFE_WIZARD_CONFIGURATION.LABEL.TYPE' | translate"
				[description]="getDigitalCertificateTypeLabel()"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.CERTIFICATE_NAME'
						| translate
				"
				[description]="
					getFormControlValueDigitalCertificateDataStep(
						'digitalCertificateFileName'
					)
				"
			></atlas-summary-item>
			<atlas-summary-item
				[label]="
					'NFE_WIZARD_CONFIGURATION.LABEL.VALID_UNTIL' | translate
				"
				[description]="
					getFormControlValueDigitalCertificateDataStep(
						'digitalCertificateExpirationDate'
					) | dateTransform
				"
			></atlas-summary-item>
		</atlas-summary-card>
	</atlas-summary-container>
</atlas-section>

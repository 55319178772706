import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	signal,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { IAtlasUploader } from "@shared/types/atlas/i-atlas-uploader";
import { DigitalCertificateDataStepFormGroup } from "../types/invoice-config-form";
import { Subject, takeUntil } from "rxjs";
import { WizardInvoiceConfigurationService } from "@data/services/wizard-invoice-configuration.service";
import { InvoiceConfigSteps } from "@modules/invoicing/pages/invoice/invoice-configuration-wizard/types/invoice-config-steps";
import { AtlasToggleItemChangeEvent } from "@modules/financeiro/components/create-new-receipt-wizard/types/atlas-toggle-item-change-event";
import {
	BaseCertificateA3Model,
	WebPkiService
} from "@shared/services/web-pki.service";
import { DateTime } from "luxon";

@Component({
	selector: "app-digital-certificate-data-step",
	templateUrl: "./digital-certificate-data-step.component.html",
	styleUrl: "./digital-certificate-data-step.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalCertificateDataStepComponent
	implements OnInit, OnDestroy, OnChanges
{
	private _onDestroy$ = new Subject<boolean>();

	@Input() currentStep: string = "";

	@Input() invoiceConfigForm: FormGroup | null = null;

	@ViewChild("atlasUploader")
	protected atlasUploader!: ElementRef<IAtlasUploader>;

	@Input()
	public formGroupName!: string;

	protected form!: FormGroup<DigitalCertificateDataStepFormGroup>;

	protected showPasswordInput = signal(false);
	protected showValidatedCertificateOnList = signal(false);
	protected digitalCertificateType = signal("a1");
	protected isDevEnv = false;
	protected webPkiNotInstalled = signal<boolean | null>(null);
	protected digitalCertificatesA3 = signal<BaseCertificateA3Model[] | null>(
		[]
	);

	constructor(
		private rootFormGroup: FormGroupDirective,
		private wizardInvoiceConfigurationService: WizardInvoiceConfigurationService,
		private webPkiService: WebPkiService
	) {}

	ngOnInit(): void {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<DigitalCertificateDataStepFormGroup>;

		this.controlDigitalCertificatesInputsWhenValidated();
		this.webPkiService.init();
		this.watchObservablesByWebPkiService();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.currentStep.currentValue ==
			InvoiceConfigSteps.DIGITAL_CERTIFICATE
		) {
			this.wizardInvoiceConfigurationService.trackWizard(
				"step_certificate_digital"
			);
		}
	}

	ngOnDestroy(): void {
		this._onDestroy$.next(true);
	}

	protected changeCertificateType(event: Event): void {
		const atlasItemToggleChangeEvent = event as AtlasToggleItemChangeEvent;

		if (atlasItemToggleChangeEvent.detail.checked) {
			this.digitalCertificateType.set("a1");
			this.form.controls.digitalCertificateType.patchValue("a1");
			return;
		}

		this.digitalCertificateType.set("a3");
		this.form.controls.digitalCertificateType.patchValue("a3");
	}

	protected uploadSuccess(): void {
		this.showPasswordInput.set(true);

		const allFiles = this.atlasUploader.nativeElement.getAllFiles();
		const digitalCertificateEventResponse =
			this.atlasUploader.nativeElement.getAllFiles()[allFiles.length - 1];

		const file = new File(
			[digitalCertificateEventResponse],
			digitalCertificateEventResponse.name,
			{ type: digitalCertificateEventResponse.type }
		);

		this.form.controls.digitalCertificateFile.setValue(file);

		this.form.controls.digitalCertificateFileName.setValue(file.name);
		this.form.controls.digitalCertificateFileName.disable();
	}

	private controlDigitalCertificatesInputsWhenValidated(): void {
		this.form.controls.digitalCertificateValidated.valueChanges
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((digitalCertificateValidated) => {
				this.showPasswordInput.set(
					this.form.controls.digitalCertificateFile.value
						? !digitalCertificateValidated
						: !!digitalCertificateValidated
				);
				this.showValidatedCertificateOnList.set(
					digitalCertificateValidated!
				);
			});
	}

	protected removeDigitalCertificate(): void {
		this.showPasswordInput.set(false);
		this.showValidatedCertificateOnList.set(false);
		this.form.controls.digitalCertificateFile.setValue(null);
		this.form.controls.digitalCertificateFileName.setValue(null);
		this.form.controls.digitalCertificateExpirationDate.setValue(null);
		this.form.controls.digitalCertificatePassword.setValue(null);
		this.form.controls.digitalCertificateValidated.setValue(false);
	}

	private watchObservablesByWebPkiService(): void {
		this.webPkiService.webPkiNotInstalled$
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((value) => {
				this.webPkiNotInstalled.set(value);
			});

		this.webPkiService.certificates$
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((value) => {
				console.log("certificados =>", value);
				this.digitalCertificatesA3.set(value);
			});
	}

	protected installWebPki(): void {
		this.webPkiService.redirectToInstallPage();
	}

	protected selectedCertificateA3(event: Event): void {
		const certificate = (
			event.target as unknown as {
				getSelectedOptions: () => { value: BaseCertificateA3Model };
			}
		).getSelectedOptions().value;

		this.form.controls.digitalCertificateFileName.setValue(
			certificate.subjectName
		);
		this.form.controls.digitalCertificateExpirationDate.setValue(
			this.formatExpirationDate(certificate.validityEnd)
		);
		this.form.controls.digitalCertificateA3.setValue(certificate);
		if (certificate.valid) {
			this.form.controls.digitalCertificateValidated.setValue(true);
		}
		this.webPkiService.selectCertificate(certificate);
	}

	formatExpirationDate(date: string | Date) {
		let luxonDate: DateTime = DateTime.now();
		if (typeof date === "string") {
			luxonDate = DateTime.fromISO(date);
		}
		if (date instanceof Date) {
			luxonDate = DateTime.fromJSDate(date);
		}
		return luxonDate.toFormat("yyyy-dd-MM");
	}
}

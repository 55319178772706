export function fileToBase64(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64String = (reader.result as string).split(",")[1];
			resolve(base64String);
		};
		reader.onerror = (error) => reject(error);
	});
}
